<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>single-man-actions-money</title>
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.75,14.25H16.716a1.342,1.342,0,0,0-.5,2.587l2.063.825a1.342,1.342,0,0,1-.5,2.588H15.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="17.25"
      y1="14.25"
      x2="17.25"
      y2="13.5"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="17.25"
      y1="21"
      x2="17.25"
      y2="20.25"
    />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="17.25" cy="17.25" r="6" />
    <circle fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" cx="6" cy="3.75" r="3" />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M10.555,10.888A5.251,5.251,0,0,0,.75,13.5v2.25H3l.75,7.5h4.5l.323-3.233"
    />
    <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" x1="6" y1="8.25" x2="6" y2="14.25" />
  </svg>
</template>
